import React, { useState, useEffect } from 'react'
import { useTranslation } from '../../i18n'
import SEO from '../../components/seo'
import InfoModal from './InfoModal'
import { encode, validateEmail } from '../../utils'
import * as R from 'ramda'
import { Input, PageTitle } from '../../GlobalStyles'
import {
  FootNoteWrapper,
  Form,
  FormNote,
  GetInTouchImg,
  SubmitButton,
  Textarea
} from './css'

const validateForm = (form, t) => {
  const { name, email, message } = form
  if (!name || !message || !email)
    return t('error_forgot') + t('error_required_fields')
  if (!validateEmail(email)) return t('error_title') + t('error_email')
}

const sendForm = form => {
  return fetch('/', {
    method: 'POST',
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    body: encode(form)
  })
}

const FORM_NAME = 'contact'
const INIT_FORM = { 'form-name': FORM_NAME }

const Book = () => {
  const { t } = useTranslation()
  const [form, setForm] = useState(INIT_FORM)
  const [loading, setLoading] = useState(false)
  const [modalOpen, toggleModal] = useState(false)
  const [errors, setErrors] = useState()

  const onChange = formPart => setForm({ ...form, ...formPart })
  const onFieldChange = ev => onChange({ [ev.target.name]: ev.target.value })

  const clearForm = () => setForm(INIT_FORM)
  const submitForm = () => setLoading(true)
  useEffect(() => {
    const postForm = async formData => {
      try {
        setErrors()
        const error = validateForm(formData, t)
        if (!error) {
          await sendForm(formData)
          clearForm()
        } else {
          setErrors(error)
        }
        toggleModal(true)
      } catch (e) {
        // show error message
      }
      setLoading(false)
    }
    if (loading) postForm(form)
    return () => {}
  }, [loading])

  return (
    <>
      <SEO title="Contact" keywords={[`nikos gallop`, `contact`, `form`]} />
      <PageTitle>{t('contact_title')}</PageTitle>
      <Form name={FORM_NAME} netlify data-netlify={true}>
        <input type="hidden" name="form-name" value={FORM_NAME} />
        <Input
          type="text"
          name="name"
          placeholder={t('book_input_name')}
          onChange={onFieldChange}
          value={R.propOr('', 'name', form)}
        />
        <Input
          type="email"
          name="email"
          placeholder={t('book_input_email')}
          onChange={onFieldChange}
          value={R.propOr('', 'email', form)}
        />
        <Input
          type="text"
          name="phoneNumber"
          placeholder={t('book_input_phone')}
          onChange={onFieldChange}
          value={R.propOr('', 'phoneNumber', form)}
        />
        <Textarea
          name="message"
          placeholder={t('contact_input_message')}
          onChange={onFieldChange}
          value={R.propOr('', 'message', form)}
        />
      </Form>
      <SubmitButton onClick={submitForm} loading={loading} disabled={loading}>
        {t('contact_submit_button')}
      </SubmitButton>
      <FootNoteWrapper>
        <FormNote>{t('contact_foot_note')}</FormNote>
        <GetInTouchImg />
      </FootNoteWrapper>
      <InfoModal
        onClose={() => toggleModal(false)}
        open={modalOpen}
        error={errors}
      />
    </>
  )
}

export default Book
