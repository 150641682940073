import styled from 'styled-components'
import { createStatic } from 'styled-components-breakpoint'
import { Button, Input, Text } from '../../GlobalStyles'
import Img from '../../components/Img'

const breakpoint = createStatic({ footnote: 450 })

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  margin: 0;
`

export const Textarea = styled(Input).attrs({
  as: 'textarea',
  rows: '4'
})`
  height: auto;
`

export const FormNote = styled(Text)`
  font-size: 18px;
  white-space: pre-line;
`

export const FootNoteWrapper = styled.div`
  margin-bottom: 30px;
  ${breakpoint.footnote`
    max-width: 400px;
    margin: 0 auto;
    margin-bottom: 30px;
  `};
`

export const GetInTouchImg = styled(Img).attrs({
  filename: 'get-in-touch.png'
})`
  max-width: 250px;
  margin-top: 20px;
  margin-left: auto;
`

export const SubmitButton = styled(Button)`
  margin: 0 auto 35px;
`
