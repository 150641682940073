import React from 'react'
import styled from 'styled-components'
import { useTranslation } from '../../i18n'
import Modal from '../../components/Modal'
import { SecondaryButton, SecondaryText } from '../../GlobalStyles'
import successIcon from '../../images/success-icon.png'
import failedIcon from '../../images/error-icon.png'

const SuccessModal = ({ open, onClose, error }) => {
  const { t } = useTranslation()
  return (
    <Modal open={open}>
      <Wrapper hasErrors={error}>
        <Message>{error ? error : t('contact_success_message')}</Message>
        <OKButton onClick={onClose}>{t('book_success_button')}</OKButton>
      </Wrapper>
    </Modal>
  )
}

export default SuccessModal

const Wrapper = styled.div`
  position: relative;
  padding-top: 10px;
  padding-right: 50px;
  padding-bottom: 80px;
  background: url(${props => (props.hasErrors ? failedIcon : successIcon)});
  background-repeat: no-repeat;
  background-size: ${props => (props.hasErrors ? '20px 100px' : '80px 100px')};
  background-position: ${props =>
    props.hasErrors ? 'calc(100% - 10px) 20px' : 'right'};
`

const Message = styled(SecondaryText)`
  font-size: 20px;
  color: #aaa;
  width: 260px;
  display: block;
  white-space: pre-line;
`

const OKButton = styled(SecondaryButton)`
  margin: 30px auto 0;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
`
